import "./partners.scss";

// Swiper
import "swiper/css";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { partnerList } from "./constants";
import { isMobile, isTablet } from "src/static";

function PartnersSection() {
  return (
    <div className="section-partners" id="integrations">
      <div className="partners-wrap">
        <h2 className="container">
          Our partners who are
          <span> integrarted with us</span>
        </h2>
        <div className="partners-wrap-logos">
          <Swiper
            spaceBetween={40}
            slidesPerView={isMobile ? 2 : isTablet ? 3 : 4}
            autoplay={{
              delay: 0,
              disableOnInteraction: false,
            }}
            speed={5000}
            modules={[Autoplay]}
            className="partners-slide-cards"
            loop
            freeMode={true}
          >
            {partnerList.map((el, i) => (
              <SwiperSlide key={i} className="partners-slide-card">
                <div className="partners-img-wrap">
                  <img src={el.image} alt="partners logos" />
                </div>
                <h4>{el.title}</h4>
                <p>{el.text}</p>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default PartnersSection;
