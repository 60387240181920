import { FacebookSvg, TelegramFilledSvg } from "src/assets/svg";
import "./footer.scss";

function FooterSection() {
  return (
    <div className="footer">
      <div className=" container footer-wrap">
        <div className="footer-info">
          <h4>LEADER ELD</h4>
          <p>
            FMCSA-certified ELD compliance solution, which makes life for truck
            drivers easier
          </p>
          <div className="footer-medias">
            <div>
              <TelegramFilledSvg />
            </div>
            <div>
              <FacebookSvg />
            </div>
          </div>
        </div>
        <div className="footer-columns">
          <p className="footer-title">LINKS</p>
          <a href="#about">Products</a>
          <a href="#partners">Features</a>
          <a href="#price">Store</a>
          <a href="#price">Integrations</a>
        </div>
        <div className="footer-columns">
          <p className="footer-title">USER AGREEMENT</p>
          <a href="/terms-of-service">
            <p>Terms of use</p>
          </a>
          <a href="/privacy-policy">
            <p>Privacy Policy</p>
          </a>
          <a href="/returns">
            <p>Returns</p>
          </a>
        </div>
        <div className="footer-columns">
          <p className="footer-title">ADDRESS</p>
          <p>USA</p>
          <p>+1 (111) 111 11-11</p>
          <button className="button-filled">Contact us</button>
        </div>
      </div>
      <p className="container footer-copyright ">
        Copyright 2025. All rights reserved
      </p>
    </div>
  );
}

export default FooterSection;
