import "./privacy.scss";

function Returns() {
  return (
    <div className="privacy container">
      <h4>Returns</h4>

      <p>
        There are a few important things to keep in mind when returning a
        product you purchased online from Leader ELD:
      </p>

      <ul>
        <li>
          You have to return an item from the date you received it. You may
          cancel the contract and return your products for any reason within 7
          calendar days of the day you receive the product(s).
        </li>
        <li>
          If a fault is found after 7 days from delivery, you should contact us
          and we will replace or repair the product, at our discretion.
        </li>
        <li>
          Only items that have been purchased directly from Leadereld.com can be
          returned to us. Items purchased from dealers or resellers and not
          Leadereld.com directly are not eligible for return, refund, or
          exchange.
        </li>
      </ul>

      <p>To return an item, please contact us.</p>

      <p>
        Please return items with all accessories and packaging. If you do not,
        we may either deny the return or allow a return with a nonrefundable
        deduction on your refund for what is missing.
      </p>

      <ul>
        <li>
          Items need to be returned in a like-new condition. Items that are
          damaged, unsanitary, dented, or scratched may be denied a return.
        </li>
        <li>
          Pack the item carefully so that it will not be damaged during the
          shipping process. We suggest using a box and bubble wrap.
        </li>
        <li>
          Please ensure that the item you're returning is repackaged with all
          the cords, adapters, and documentation that were included when you
          received it.
        </li>
        <li>
          It is recommended to use a secure shipping method such as insured UPS
          to send the item so that you have tracking for the package. Leader ELD
          is not responsible for items lost in transit and a return cannot be
          processed until the item is received.
        </li>
      </ul>

      <p>All returns should be sent to the following address:</p>

      <p>Leader ELD 680 East Market St, Suite 113 Akron, OH 44304</p>

      <p>
        Once we receive your item, a refund is initiated immediately. If you
        canceled a pickup item, and you were billed for it, we initiate your
        refund immediately after you submit your cancellation request.
      </p>

      <h4>Refunds</h4>

      <p>
        For returns by mail, once we receive your return, we will process it
        within 3-5 business days. With a few exceptions, we will reimburse you
        for returned items in the same way you paid for them. Funds should be
        available within 5 business days. If you prefer, you can make an
        exchange for the same item.
      </p>

      <p>
        If you have further questions regarding return and refund policy, please
        feel free to visit our contact page.
      </p>
    </div>
  );
}

export default Returns;
