import { Collapse, CollapseProps } from "antd";
import "./features.scss";
import { useState } from "react";
import { Anchor, Col, Row } from "antd";

const faqCommentsData: CollapseProps["items"] = [
  {
    key: "1",
    label: "Monitor every vehicle",
    children: (
      <p style={{ fontSize: 16 }}>
        Access all essential vehicle information in one place: vehicle and
        driver duty status, current location, fuel level, and speed.
        Additionally, monitor drivers' remaining Hours of Service (HOS) time and
        stay informed about any HOS violations.
      </p>
    ),
  },
  {
    key: "2",
    label: "Customizable map",
    children: (
      <p style={{ fontSize: 16 }}>
        With a customizable dashboard map, you can select the style that suits
        you best. Apply filters to view traffic conditions, group vehicles, and
        locate nearby weigh stations, CAT scales, and a wide range of truck
        stops.
      </p>
    ),
  },
  {
    key: "3",
    label: "Weather map",
    children: (
      <p style={{ fontSize: 16 }}>
        Plan ahead by using weather filters to stay informed about upcoming
        rainfall and snowstorms.
      </p>
    ),
  },
  {
    key: "4",
    label: "Live Location Share",
    children: (
      <p style={{ fontSize: 16 }}>
        Satisfy your brokers and shippers by sharing your vehicle's live
        location. This eliminates stress and concerns about the driver's
        whereabouts and estimated arrival times.
      </p>
    ),
  },
  {
    key: "5",
    label: "API Integration",
    children: (
      <p style={{ fontSize: 16 }}>
        Enhance efficiency with API integration between your ELD system and
        brokers
      </p>
    ),
  },
];

function FeaturesSection() {
  const [activeIndex, setActiveIndex] = useState(1);

  const handleScroll = (e: any) => {
    const element = e.target;
    const scrollPercent =
      element.scrollTop / (element.scrollHeight - element.clientHeight);
    const newIndex = Math.floor(scrollPercent * 5);
    setActiveIndex(newIndex + 1);
  };
  console.log("activeIndex", activeIndex);

  return (
    <div className="features" id="features">
      <div className="features-wrap container">
        <h2 data-aos="fade-up" data-aos-delay="300" data-aos-duration="1500">
          Features
        </h2>
        <div className="features-content">
          <div className="features-left" onScroll={handleScroll}>
            {Array.from({ length: 5 }).map((el, index) => (
              <div key={index}>
                <img
                  src={require(`src/assets/img/features/features${
                    index + 1
                  }.png`)}
                  alt="product"
                  draggable={false}
                />
              </div>
            ))}
          </div>
          <div className="features-right">
            <Collapse
              items={faqCommentsData}
              defaultActiveKey={["1"]}
              bordered={false}
              expandIconPosition={"end"}
              ghost={true}
              accordion
              activeKey={String(activeIndex)}
              onChange={(e) => setActiveIndex(Number(e[0]))}
            />
          </div>

          {/* <div
            className="features-left"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-duration="1500"
          >
            {activeSlide ? (
              <img
                src={require(`src/assets/img/features/features${activeSlide}.png`)}
                alt="product"
                draggable={false}
              />
            ) : (
              <img
                src={require(`src/assets/img/features/features1.png`)}
                alt="product"
                draggable={false}
              />
            )}
          </div>
          <div className="features-right">
            <Collapse
              items={faqCommentsData}
              defaultActiveKey={["1"]}
              bordered={false}
              expandIconPosition={"end"}
              ghost={true}
              accordion
              onChange={(e) => setActiveSlide(e[0])}
              // expandIcon={() => <ArrowUpSvg />}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default FeaturesSection;
