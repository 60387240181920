import { Anchor } from "antd";
import { useEffect, useState } from "react";
import { CloseSvg, MenuSvg } from "src/assets/svg";
import { isTablet } from "src/static";
import "./navbar.scss";

function Navbar() {
  const [scrolled, setScrolled] = useState(false);
  const [visible, setVisible] = useState(false);

  const menu = [
    {
      key: "section-1",
      href: "#hero",
      title: "About",
    },
    {
      key: "section-2",
      href: "#products",
      title: "Products",
    },
    {
      key: "section-3",
      href: "#features",
      title: "Features",
    },
    {
      key: "section-4",
      href: "https://store.leadereld.com/",
      title: "Store",
    },
    {
      key: "section-5",
      href: "#integrations",
      title: "Integrations",
    },
    {
      key: "section-6",
      href: "#contact",
      title: "Request Demo",
    },
  ];

  const handlePress = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className={`navbar ${scrolled && !isTablet ? "scrolled" : ""}`}>
      {!isTablet ? (
        <div className="navbar-content container">
          <div className="navbar-logo">
            <a href="#">
              <img
                src={require("src/assets/img/logo.png")}
                alt="logo"
                draggable={false}
              />
              <p>LEADER ELD</p>
            </a>
          </div>
          <div className="navbar-menu">
            <Anchor direction="horizontal" items={menu} />
          </div>
          <div className="navbar-action">
            <a href="#">
              <button className="button-outlined">Login</button>
            </a>
          </div>
        </div>
      ) : (
        <div className={`navbar-mobile ${visible ? "expanded" : ""}`}>
          <div className="navbar-mobile-header">
            <div className="navbar-logo">
              <a href="#">
                <img
                  src={require("src/assets/img/logo.png")}
                  alt="logo"
                  draggable={false}
                />
                <p>LEADER ELD</p>
              </a>
            </div>
            <button className="menu-button" onClick={handlePress}>
              {visible ? (
                <CloseSvg color="#6800ff" />
              ) : (
                <MenuSvg color="#6800ff" />
              )}
            </button>
          </div>
          {visible && (
            <div className="navbar-mobile-body">
              <div className="navbar-mobile-menu">
                {menu.map((menu, i) => (
                  <a href={menu.href} key={i} onClick={() => setVisible(false)}>
                    {menu.title}
                  </a>
                ))}
              </div>
              <a href="#">
                <button className="button-outlined navbar-mobile-button">
                  Login
                </button>
              </a>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Navbar;
