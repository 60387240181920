import { useNavigate } from "react-router-dom";
import ContactSection from "./components/contact";
import FeaturesSection from "./components/features";
import HeroSection from "./components/hero";
import Navbar from "./components/navbar";
import SectionProducts from "./components/products";
import StoreSection from "./components/store";
import "./home.scss";
import FooterSection from "./components/footer";
import PartnersSection from "./components/partners";

function Home() {
  const navigation = useNavigate();
  return (
    <div className="home">
      <div className="background-lines-container">
        <div className="backline"></div>
        <div className="backline"></div>
        <div className="backline"></div>
        <div className="backline"></div>
        <div className="backline"></div>
        <div className="backline"></div>
        <div className="backline"></div>
      </div>
      <Navbar />
      <HeroSection />
      <SectionProducts />
      <FeaturesSection />
      <StoreSection />
      <PartnersSection />
      <ContactSection />
      <FooterSection />
    </div>
  );
}

export default Home;
