import { Image } from "antd";
import { useEffect } from "react";
import { productsData } from "./constants";
import "./products.scss";

import AOS from "aos";
import "aos/dist/aos.css";

window.addEventListener("scroll", function () {
  const card1 = document.querySelector(".card-2") as HTMLElement;
  const card2 = document.querySelector(".card-4") as HTMLElement;
  if (card1 && card2) {
    var scrollAmount = window.pageYOffset || document.documentElement.scrollTop;
    var moveDistance = scrollAmount * 0.05; // Example multiplier

    // Apply the movement
    card1.style.transform = card2.style.transform =
      "translate3d(0px, " + moveDistance + "px, 0px)";
  }
});

function SectionProducts() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
      once: true,
    });
  }, []);

  return (
    <div className="section-products" id="products">
      <div className="products-wrap container">
        <div className="products-title">
          <h2 data-aos="fade-up" data-aos-delay="300" data-aos-duration="1500">
            Our Products
          </h2>
          <p data-aos="fade-up" data-aos-delay="300" data-aos-duration="1500">
            Everything you need to manage your fleet is now integrated into a
            single platform. Experience streamlined compliance with our
            registered ELD, real-time GPS tracking, efficient dispatching, and
            advanced dash camera features-all in one place.
          </p>
        </div>
        <div className="products-cards">
          <img
            className="background-effect"
            src={require("src/assets/img/gradient.png")}
            alt="background effect"
            draggable={false}
          />

          {productsData.map((card, i) => (
            <div className={`img-card card-${i + 1}`} key={i}>
              <div className="img-card-wrap">
                <Image
                  className="img-card-img"
                  src={require(`src/assets/img/products/${card.img}.png`)}
                  draggable={false}
                />
              </div>
              <div className="img-card-detail">
                <h4>{card.title}</h4>
                <p>{card.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SectionProducts;
