export interface IconProps {
  width?: number;
  height?: number;
  color?: string;
}

export const PhoneFilledSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className="phone-icon"
  >
    <path d="M19.231 15.2617L16.691 14.9717C16.081 14.9017 15.481 15.1117 15.051 15.5417L13.211 17.3817C10.381 15.9417 8.06102 13.6317 6.62102 10.7917L8.47102 8.94172C8.90102 8.51172 9.11102 7.91172 9.04102 7.30172L8.75102 4.78172C8.63102 3.77172 7.78102 3.01172 6.76102 3.01172H5.03102C3.90102 3.01172 2.96102 3.95172 3.03102 5.08172C3.56102 13.6217 10.391 20.4417 18.921 20.9717C20.051 21.0417 20.991 20.1017 20.991 18.9717V17.2417C21.001 16.2317 20.241 15.3817 19.231 15.2617Z" />
  </svg>
);

export const TelegramFilledSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="800px"
    height="800px"
    viewBox="0 0 48 48"
    fill="none"
    className="telegram-icon"
  >
    <path d="M41.4193 7.30899C41.4193 7.30899 45.3046 5.79399 44.9808 9.47328C44.8729 10.9883 43.9016 16.2908 43.1461 22.0262L40.5559 39.0159C40.5559 39.0159 40.3401 41.5048 38.3974 41.9377C36.4547 42.3705 33.5408 40.4227 33.0011 39.9898C32.5694 39.6652 24.9068 34.7955 22.2086 32.4148C21.4531 31.7655 20.5897 30.4669 22.3165 28.9519L33.6487 18.1305C34.9438 16.8319 36.2389 13.8019 30.8426 17.4812L15.7331 27.7616C15.7331 27.7616 14.0063 28.8437 10.7686 27.8698L3.75342 25.7055C3.75342 25.7055 1.16321 24.0823 5.58815 22.459C16.3807 17.3729 29.6555 12.1786 41.4193 7.30899Z" />
  </svg>
);

export const FacebookSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="#000000"
    width="800px"
    height="800px"
    viewBox="0 0 32 32"
    className="facebook-icon"
  >
    <path d="M21.95 5.005l-3.306-.004c-3.206 0-5.277 2.124-5.277 5.415v2.495H10.05v4.515h3.317l-.004 9.575h4.641l.004-9.575h3.806l-.003-4.514h-3.803v-2.117c0-1.018.241-1.533 1.566-1.533l2.366-.001.01-4.256z" />
  </svg>
);

export const CloseSvg = ({ color }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="800px"
    height="800px"
    viewBox="0 0 24 24"
    fill="none"
    className="close-svg"
  >
    <circle cx="12" cy="12" r="10" stroke={color} strokeWidth="1.5" />
    <path
      d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
export const MenuSvg = ({ color }: IconProps) => (
  <svg
    width="800px"
    height="800px"
    viewBox="0 0 24 24"
    fill="none"
    className="menu-svg"
  >
    <g id="Menu / Menu_Alt_02">
      <path
        id="Vector"
        d="M11 17H19M5 12H19M11 7H19"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
