import "./store.scss";

function StoreSection() {
  return (
    <div className="store" id="store">
      <div className="store-wrap container">
        <h2 data-aos="fade-up" data-aos-delay="300" data-aos-duration="1500">
          <span>LEADER ELD</span> Mobile Application
        </h2>
        <div className="store-content">
          <div
            className="store-row"
            data-aos="fade-right"
            data-aos-delay="300"
            data-aos-duration="1500"
          >
            <div className="store-row-card">
              <div className="store-row-card-dot-wrap">
                <div className="store-row-card-dot" />
              </div>
              <h4>Compliance</h4>
              <p>
                Fully FMCSA compliant, ensuring you pass roadside inspections
                with confidence.
              </p>
            </div>
            <div className="store-row-card">
              <div className="store-row-card-dot-wrap">
                <div className="store-row-card-dot" />
              </div>
              <h4>Maintain and audit records</h4>
              <p>
                Effectively monitor and maintain your records, while benefiting
                from the flexibility offered by e-logs
              </p>
            </div>
            <div className="store-row-card">
              <div className="store-row-card-dot-wrap">
                <div className="store-row-card-dot" />
              </div>
              <h4>Inspection</h4>
              <p>
                Display only the legally required information for recording
                purposes.
              </p>
            </div>
          </div>
          <div className="store-row">
            <img
              src={require("src/assets/img/store.png")}
              alt="phone"
              className="store-row-img"
              draggable={false}
            />
          </div>
          <div
            className="store-row"
            data-aos="fade-left"
            data-aos-delay="300"
            data-aos-duration="1500"
          >
            <div className="store-row-card">
              <div className="store-row-card-dot-wrap">
                <div className="store-row-card-dot" />
              </div>
              <h4>Night Theme</h4>
              <p>
                Featuring a darkened design to reduce eye strain and
                power-saving capabilities to extend your device's battery life.
              </p>
            </div>
            <div className="store-row-card">
              <div className="store-row-card-dot-wrap">
                <div className="store-row-card-dot" />
              </div>
              <h4>Easy Access</h4>
              <p>
                Easily access your most frequently used pages and efficiently
                certify or bulk-certify all your past uncertified records.
              </p>
            </div>
            <div className="store-row-card">
              <div className="store-row-card-dot-wrap">
                <div className="store-row-card-dot" />
              </div>
              <h4>Telematics & Data</h4>
              <p>
                Through the use of diagnostic tools, you may analyze the major
                performance indicators on your truck and get crucial details
                about how it is working.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StoreSection;
